import React from 'react';
import {
  Box, AppBar, Toolbar,
} from '@mui/material';
import {
  BrowserRouter,
} from 'react-router-dom';
import useGlobal from 'global-state/store';
import { useTranslation } from 'react-i18next';
import Cookies from 'components/cookies/Cookies';
import TopToolbar from 'components/TopToolbar';
import Footer from './Footer';
import LaboxAppsRoutes from './LaboxAppsRoutes';
import GlobalSnackbar from './GlobalSnackbar';
import ScrollToTop from './ScrollToTop';

export default function Home() {
  const { t } = useTranslation();
  const [, globalActions] = useGlobal();

  return (
    <BrowserRouter>
      <Box sx={{
        display: 'flex',
      }}
      >
        <ScrollToTop />
        <GlobalSnackbar />
        <AppBar
          position="fixed"
          elevation={1}
          sx={{
            width: '100%',
            backgroundColor: 'background.default',
          }}
          color="inherit"
        >
          <Toolbar>
            <TopToolbar />
          </Toolbar>
        </AppBar>
        <Box
          component="main"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100vw',
            alignItems: 'center',
            pt: { xs: 10, md: 12 },
          }}
        >
          <Box sx={{
            minHeight: '75vh',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          >
            <LaboxAppsRoutes />
          </Box>
          <Footer />
          <Cookies
            cookieName="agreed-to-cookies-on-labox-apps"
            t={t}
            globalActions={globalActions}
          />
        </Box>
      </Box>
    </BrowserRouter>
  );
}
