import React, { useRef } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import devices from 'staticFiles/devices.png';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Points from './points/Points';
import OurSolutions from './ourSolutions/OurSolutions';
import QuickAccesses from './QuickAccesses';
import TheTeam from './ourTeam/TheTeam';
import StayTuned from './StayTuned';
import BackBoxes from './BackBoxes';
import Memberships from './MemberShips';

export default function Welcome() {
  const { t } = useTranslation();
  const quickAccessesSection = useRef(null);

  const scrollToRef = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop - 60,
      behavior: 'smooth',
    });
  };

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 3, width: '100%',
    }}
    >
      <BackBoxes />
      <Box sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        alignItems: 'center',
        gap: { xs: 2, sm: 3, lg: 15 },
        px: 1,
      }}
      >
        <Box sx={{
          display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 3,
        }}
        >
          <Typography
            sx={{
              typography: {
                xs: 'h5',
                md: 'h4',
              },
              width: { xs: '90vw', sm: '45vw' },
              maxWidth: 500,
              textAlign: { xs: 'center', sm: 'left' },
              fontWeight: 'bold',
            }}
          >
            Labox Applications
          </Typography>
          <Typography
            color="ourOrange.main"
            sx={{
              typography: {
                xs: 'h4',
                md: 'h3',
              },
              width: { xs: '90vw', sm: '45vw' },
              maxWidth: 500,
              textAlign: { xs: 'center', sm: 'left' },
            }}
          >
            <Trans i18nKey="moto" components={{ 1: <strong /> }} />
          </Typography>
          <Button
            variant="contained"
            sx={{
              fontSize: { xs: 'small', lg: 'medium' },
              borderRadius: '20px',
              alignSelf: { xs: 'center', sm: 'flex-start' },
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            onClick={() => scrollToRef(quickAccessesSection)}
          >
            {t('quick_accesses')}
            <KeyboardArrowDownIcon />
          </Button>
        </Box>
        <Box component="img" src={devices} sx={{ width: { xs: '90vw', sm: '45vw' }, maxWidth: 800, height: 'auto' }} />
      </Box>
      <Points />
      <OurSolutions />
      <QuickAccesses quickAccessesSection={quickAccessesSection} />
      <TheTeam />
      <StayTuned />
      <Memberships />
    </Box>
  );
}
