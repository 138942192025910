import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import quickAccesses from 'staticFiles/quick_accesses.png';
import { Link } from 'react-router-dom';
import alco from 'staticFiles/alco.svg';
import gc from 'staticFiles/gc.svg';
import tt from 'staticFiles/tt.svg';
import orgs from 'staticFiles/orgs.svg';
import cc from 'staticFiles/cc.svg';
import inventories from 'staticFiles/inventory.svg';
import optimix from 'staticFiles/optimix.svg';

export default function QuickAccesses({ quickAccessesSection }) {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 3,
        py: 3,
      }}
      ref={quickAccessesSection}
    >
      <Box
        component="img"
        sx={{
          width: '90%',
          maxWidth: 400,
          height: 'auto',
          borderRadius: 3,
          objectFit: 'cover',
        }}
        src={quickAccesses}
      />
      <Box sx={{
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignItems: { xs: 'stretch', lg: 'flex-start' },
        justifyContent: 'center',
        gap: { xs: 2, lg: 4, xl: 6 },
        mt: 3,
      }}
      >
        <Box sx={{ width: { xs: '45%', sm: 130 } }}>
          <QuickAccess
            link="https://alcoholometry.labox-apps.com"
            logo={alco}
            text={t('alcoholometry.title')}
          />
        </Box>
        <Box sx={{ mt: { xs: 0, lg: 15 }, width: { xs: '45%', sm: 150 } }}>
          <QuickAccess
            link="https://gc.digitank.labox-apps.com"
            logo={gc}
            text={t('gc.title')}
          />
        </Box>
        <Box sx={{ width: { xs: '45%', sm: 130 } }}>
          <QuickAccess
            link="https://tt.digitank.labox-apps.com"
            logo={tt}
            text={t('tt.title')}
          />
        </Box>
        <Box sx={{ mt: { xs: 0, lg: 15 }, width: { xs: '45%', sm: 130 } }}>
          <QuickAccess
            link="https://orgs-ws.labox-apps.com"
            logo={orgs}
            text={t('orgs.title')}
          />
        </Box>
        <Box sx={{ width: { xs: '45%', sm: 130 } }}>
          <QuickAccess
            link="https://cc.digitank.labox-apps.com"
            logo={cc}
            text={t('cc.title')}
          />
        </Box>
        <Box sx={{ mt: { xs: 0, lg: 15 }, width: { xs: '45%', sm: 130 } }}>
          <QuickAccess
            link="https://inventory.labox-apps.com"
            logo={inventories}
            text={t('inventories.title')}
          />
        </Box>
        <Box sx={{ width: { xs: '45%', sm: 130 } }}>
          <QuickAccess
            link="https://optimix.labox-apps.com"
            logo={optimix}
            text={t('optimix.title')}
          />
        </Box>
      </Box>

    </Box>
  );
}

function QuickAccess({
  link, text, logo,
}) {
  return (
    <Link
      to={link}
      target="_blank"
      style={{ textDecoration: 'none', color: 'inherit', height: '100%' }}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: 1,
        height: '100%',
        transition: 'transform 0.3s ease-in-out',
        '&:hover': {
          transform: 'scale(1.05)',
        },
      }}
      >
        <Box
          component="img"
          sx={{
            width: 90,
            height: 'auto',
            borderRadius: 3,
            objectFit: 'cover',
          }}
          src={logo}
          alt={text}
        />
        <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
          <Typography
            sx={{
              fontWeight: 'bold',
            }}
          >
            {text}
          </Typography>
        </Box>

      </Box>
    </Link>
  );
}
