import React from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { Trans } from 'react-i18next';
import lbxArrowLeft from 'staticFiles/lbx_arrow_left.png';
import lbxArrowRight from 'staticFiles/lbx_arrow_right.png';
import lbxUp from 'staticFiles/lbx_up.png';
import lbxTarget from 'staticFiles/lbx_target.png';
import lbxComplex from 'staticFiles/lbx_complex.png';

export default function Points() {
  const isSmallScreen = useMediaQuery('(max-width: 928px)');

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: '100%',
      maxWidth: 1800,
      px: {
        xs: 3, md: 20, lg: 30, xl: 50,
      },
      gap: { xs: 4, sm: 0 },
    }}
    >
      {!isSmallScreen && (
        <Box sx={{
          position: 'absolute',
          left: '50%',
          top: 570,
          width: '2px',
          height: 670,
          backgroundColor: 'primary.main',
        }}
        />
      )}

      <SimplicityPoint />
      <RigorPoint />
      <FlexibilityPoint />
    </Box>
  );
}

function SimplicityPoint() {
  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1, alignSelf: 'flex-start',
    }}
    >
      <Box component="img" src={lbxUp} sx={{ width: { xs: 30, md: 50 }, height: 'auto' }} />
      <Box sx={{ position: 'relative' }}>
        <Typography
          color="secondary.main"
          sx={{
            fontSize: { xs: 60, md: 90 },
            fontWeight: 'bold',
            position: 'absolute',
            left: { xs: '-20px', md: '-40px' },
            top: { xs: '-30px', md: '-50px' },
            opacity: 0.3,
            zIndex: -1,
            // Additional shadow styling
            filter: 'drop-shadow(2px 4px 6px black)',
          }}
        >
          01.
        </Typography>
        <Typography
          color="ourOrange.main"
          sx={{
            typography: {
              xs: 'h6',
              md: 'h4',
            },
            textAlign: 'left',
            mb: 3,
          }}
        >
          <Trans i18nKey="simplicity.title" components={{ 1: <strong /> }} />
        </Typography>
      </Box>
      <TextArrowEnd text={(
        <Trans
          i18nKey="simplicity.1"
          components={{ 1: <strong style={{ fontSize: 'larger' }} /> }}
        />
      )}
      />
      <TextArrowEnd text={(
        <Trans
          i18nKey="simplicity.2"
          components={{ 1: <strong style={{ fontSize: 'larger' }} /> }}
        />
      )}
      />
      <TextArrowEnd text={(
        <Trans
          i18nKey="simplicity.3"
          components={{ 1: <strong style={{ fontSize: 'larger' }} /> }}
        />
      )}
      />
    </Box>
  );
}

function RigorPoint() {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 1,
      alignSelf: 'flex-end',
      mt: { xs: 0, sm: -10 },
    }}
    >
      <Box component="img" src={lbxTarget} sx={{ width: { xs: 30, md: 50 }, height: 'auto' }} />
      <Box sx={{ position: 'relative' }}>
        <Typography
          color="secondary.main"
          sx={{
            fontSize: { xs: 60, md: 90 },
            fontWeight: 'bold',
            position: 'absolute',
            left: { xs: '-20px', md: '-40px' },
            top: { xs: '-30px', md: '-50px' },
            opacity: 0.3,
            zIndex: -1,
            // Additional shadow styling
            filter: 'drop-shadow(2px 4px 6px black)',
          }}
        >
          02.
        </Typography>
        <Typography
          color="ourOrange.main"
          sx={{
            typography: {
              xs: 'h6',
              md: 'h4',
            },
            textAlign: 'left',
            mb: 3,
          }}
        >
          <Trans i18nKey="rigor.title" components={{ 1: <strong /> }} />
        </Typography>
      </Box>
      <TextArrowStart text={(
        <Trans
          i18nKey="rigor.1"
          components={{ 1: <strong style={{ fontSize: 'larger' }} /> }}
        />
      )}
      />
      <TextArrowStart text={(
        <Trans
          i18nKey="rigor.2"
          components={{ 1: <strong style={{ fontSize: 'larger' }} /> }}
        />
      )}
      />
    </Box>
  );
}

function FlexibilityPoint() {
  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1, mt: { xs: 0, sm: -10 },
    }}
    >
      <Box component="img" src={lbxComplex} sx={{ width: { xs: 30, md: 50 }, height: 'auto' }} />
      <Box sx={{ position: 'relative' }}>
        <Typography
          color="secondary.main"
          sx={{
            fontSize: { xs: 60, md: 90 },
            fontWeight: 'bold',
            position: 'absolute',
            left: { xs: '-20px', md: '-40px' },
            top: { xs: '-30px', md: '-50px' },
            opacity: 0.3,
            zIndex: -1,
            // Additional shadow styling
            filter: 'drop-shadow(2px 4px 6px black)',
          }}
        >
          03.
        </Typography>
        <Typography
          color="ourOrange.main"
          sx={{
            typography: {
              xs: 'h6',
              md: 'h4',
            },
            textAlign: 'left',
            mb: 3,
          }}
        >
          <Trans i18nKey="flexibility.title" components={{ 1: <strong style={{ fontSize: 'larger' }} /> }} />
        </Typography>
      </Box>
      <TextArrowEnd text={(
        <Trans
          i18nKey="flexibility.1"
          components={{ 1: <strong style={{ fontSize: 'larger' }} /> }}
        />
      )}
      />
      <TextArrowEnd text={(
        <Trans
          i18nKey="flexibility.2"
          components={{ 1: <strong style={{ fontSize: 'larger' }} /> }}
        />
      )}
      />
      <TextArrowEnd text={(
        <Trans
          i18nKey="flexibility.3"
          components={{ 1: <strong style={{ fontSize: 'larger' }} /> }}
        />
      )}
      />
    </Box>
  );
}

function TextArrowEnd({ text }) {
  return (
    <Box sx={{
      display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 3,
    }}
    >
      <Typography sx={{
        typography: {
          xs: 'body2',
          md: 'body1',
        },
        textAlign: 'right',
        width: 250,
      }}
      >
        {text}
      </Typography>
      <Box component="img" src={lbxArrowLeft} sx={{ width: 20, height: 'auto' }} />
    </Box>
  );
}

function TextArrowStart({ text }) {
  return (
    <Box sx={{
      display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 3,
    }}
    >
      <Box component="img" src={lbxArrowRight} sx={{ width: 20, height: 'auto' }} />
      <Typography sx={{
        typography: {
          xs: 'body2',
          md: 'body1',
        },
        textAlign: 'left',
        width: 250,
      }}
      >
        {text}
      </Typography>
    </Box>
  );
}
