import React from 'react';
import { Box, Paper, Typography } from '@mui/material';
import unitec from 'staticFiles/unitec.png';
import spiritsvalley from 'staticFiles/spiritsvalley.png';
import frenchtech from 'staticFiles/frenchtech.jpeg';
import bm from 'staticFiles/bm.png';
import innovin from 'staticFiles/innovin.png';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function Memberships() {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'cenetr',
      width: '100%',
      px: 3,
      py: 6,
      mt: 3,
      backgroundColor: 'deepPrimray.main',
      gap: 3,
    }}
    >
      <Typography variant="h4">
        <Trans
          i18nKey="labox_apps_is_member_of"
          components={{
            1: <strong
              style={{ color: '#f4ad45' }}
            />,
          }}
        />
      </Typography>

      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        gap: 3,
      }}
      >
        <ImageWithBackground src={unitec} imageWidth="100%" link="https://www.unitec.fr/" />
        <ImageWithBackground src={bm} imageWidth="95%" link="https://bmstartupwin.com/" />
        <ImageWithBackground src={innovin} imageWidth="80%" link="https://innovin.fr/" />
        <ImageWithBackground src={spiritsvalley} imageWidth="100%" link="https://spiritsvalley.com/" />
        <ImageWithBackground src={frenchtech} imageWidth="100%" link="https://lafrenchtech.gouv.fr/" />
      </Box>
    </Box>
  );
}

function ImageWithBackground({ src, imageWidth, link }) {
  return (
    <Paper
      elevation={3}
      sx={{
        width: 220,
        height: 180,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#FFFFFF',
        borderRadius: '12px',
        overflow: 'hidden',
        transition: 'transform 0.3s ease-in-out',
        '&:hover': {
          transform: 'scale(1.05)',
        },
        cursor: 'pointer',
      }}
      component={Link}
      to={link}
      target="_blank"
    >
      <Box
        component="img"
        src={src}
        sx={{
          maxWidth: imageWidth,
          maxHeight: '100%',
          display: 'block',
        }}
      />
    </Paper>
  );
}
