import React from 'react';
import {
  Box, Button, Typography,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import guide from 'staticFiles/guide2.jpeg';
import phones from 'staticFiles/alcoiphone14.png';
import { Link } from 'react-router-dom';
import BackBoxes from 'components/BackBoxes';

export default function Alcoholometry() {
  const { t } = useTranslation();
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      gap: 9,
    }}
    >
      <BackBoxes />
      <Presentation />
      <Videos />
      <Points />
      <Button
        variant="contained"
        size="large"
        target="_blank"
        component={Link}
        to="https://alcoholometry.labox-apps.com"
      >
        {t('try_free')}
      </Button>
    </Box>
  );
}

function Presentation() {
  const { t } = useTranslation();
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      gap: 9,
      px: { xs: 3, sm: '10%', xl: '15%' },
    }}
    >
      <Typography variant="h2">
        {t('alcoholometry.title_ol')}
      </Typography>

      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
        gap: { xs: 3, sm: 6, xl: 9 },
      }}
      >
        <Link
          target="_blank"
          to="https://alcoholometry.labox-apps.com"
        >
          <Box
            component="img"
            sx={{
              width: 300,
              height: 400,
              borderRadius: 1,
              objectFit: 'cover',
            }}
            src={guide}
            alt="guide"
          />
        </Link>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          width: { xs: '100%', sm: '50%' },
          alignItems: 'flex-start',
          textAlign: 'left',
        }}
        >
          <Typography variant="h4" color="ourOrange.main">
            {t('alcoholometry.info1')}
          </Typography>
          <Typography>
            {t('alcoholometry.info2')}
          </Typography>
        </Box>
      </Box>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
        gap: { xs: 3, sm: 6, xl: 9 },
      }}
      >
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          width: { xs: '100%', sm: '50%' },
          alignItems: 'flex-start',
          textAlign: 'left',
        }}
        >
          <Typography variant="h4" color="ourOrange.main">
            {t('alcoholometry.info3')}
          </Typography>

          <Typography>
            <Trans
              i18nKey="alcoholometry.info4"
              components={{
                1: <Link
                  style={{ color: '#FFFFFF' }}
                  target="_blank"
                  to="https://alcoholometry.labox-apps.com"
                />,
              }}
            />
          </Typography>

        </Box>
        <Box
          component="img"
          sx={{
            width: 'auto',
            height: 400,
            borderRadius: 1,
            objectFit: 'cover',
          }}
          src={phones}
          alt="phones"
        />
      </Box>
    </Box>
  );
}

function Videos() {
  const { t } = useTranslation();
  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      flexWrap: 'wrap',
      width: '100%',
      px: 1,
      gap: 3,
    }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
          width: { xs: '100%', md: '45%' },
        }}
      >
        <Box
          component="iframe"
          sx={{
            width: '100%',
            maxWidth: 560,
            height: { xs: 280, md: 315 },
          }}
          src="https://www.youtube.com/embed/cZzDucs866Q"
          title={t('alcoholometry.video1.title')}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
        <Typography variant="h4" color="ourOrange.main">{t('alcoholometry.video1.title')}</Typography>
        <Typography variant="h6">{t('alcoholometry.video1.subtitle')}</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, width: { xs: '100%', md: '45%' },
        }}
      >
        <Box
          component="iframe"
          sx={{
            width: '100%',
            maxWidth: 560,
            height: { xs: 280, md: 315 },
          }}
          src="https://www.youtube.com/embed/py8C39bjdjc"
          title={t('alcoholometry.video3.title')}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
        <Typography variant="h4" color="ourOrange.main">{t('alcoholometry.video3.title')}</Typography>
        <Typography variant="h6">{t('alcoholometry.video3.subtitle')}</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, width: { xs: '100%', md: '45%' },
        }}
      >
        <Box
          component="iframe"
          sx={{
            width: '100%',
            maxWidth: 560,
            height: { xs: 280, md: 315 },
          }}
          src="https://www.youtube.com/embed/MC33-xPPOCI"
          title={t('alcoholometry.video4.title')}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
        <Typography variant="h4" color="ourOrange.main">{t('alcoholometry.video4.title')}</Typography>
        <Typography variant="h6">{t('alcoholometry.video4.subtitle')}</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, width: { xs: '100%', md: '45%' },
        }}
      >
        <Box
          component="iframe"
          sx={{
            width: '100%',
            maxWidth: 560,
            height: { xs: 280, md: 315 },
          }}
          src="https://www.youtube.com/embed/Ji39r043BTU"
          title={t('alcoholometry.video2.title')}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
        <Typography variant="h4" color="ourOrange.main">{t('alcoholometry.video2.title')}</Typography>
        <Typography variant="h6">{t('alcoholometry.video2.subtitle')}</Typography>
      </Box>
    </Box>
  );
}

function Points() {
  const { t } = useTranslation();
  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      flexWrap: 'wrap',
      gap: 3,
      backgroundColor: 'deepPrimary.main',
      width: '100%',
      py: 6,
    }}
    >
      <Point title={t('alcoholometry.point1.title')} subtitle={t('alcoholometry.point1.subtitle')} />
      <Point title={t('alcoholometry.point2.title')} subtitle={t('alcoholometry.point2.subtitle')} />
      <Point title={t('alcoholometry.point3.title')} subtitle={t('alcoholometry.point3.subtitle')} />
    </Box>
  );
}

function Point({ title, subtitle }) {
  return (
    <Box
      sx={{
        display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, width: 350,
      }}
    >
      <Typography variant="h4" color="secondary.main">
        {title}
      </Typography>
      <Typography>
        {subtitle}
      </Typography>
    </Box>
  );
}
